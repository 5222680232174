<template>
    <div class="flex-auto flex items-center">
        <div class="w-full grid grid-cols-8 gap-4">
            <div class="col-span-8 md:col-span-3">
                <div class="text-sm">
                    <p>
                        <a href="https://goo.gl/maps/QKNTife2uis5hHPj9" target="_blank">
                            C/ Trafalgar, 32, 1A
                            <br />28010 Madrid, España
                        </a>
                    </p>
                    <p>
                        <a href="tel:+34910559146">+34 910559146</a>
                    </p>
                    <p>
                        <a href="tel:+34609246293">+34 609246293</a>
                    </p>
                    <p>
                        <a href="tel:+34628104886">+34 628104886</a>
                    </p>
                    <p>
                        <a href="mailto:iam@iamarq.es">iam@iamarq.es</a>
                    </p>
                </div>
            </div>
            <div class="col-span-8 sm:col-span-5 lg:col-span-3">
                <div class="flex justify-center">
                    <a href="https://goo.gl/maps/C4v3RsY9A3QLdm6y7" target="_blank">
                        <figure>
                            <img src="../assets/images/location.jpg" class="max-w-full w-full h-auto" alt />
                        </figure>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import titleMixin from "@/mixins/titleMixin";

export default {
    name: "Contact",
    mixins: [titleMixin]
};
</script>
