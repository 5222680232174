<template>
    <div ref="overviewContainer" class="flex-auto overflow-y-auto flex" v-if="menuElement">
        <div class="grid grid-cols-12 gap-4">
            <div
                class="col-span-6 md:col-span-3 item relative flex items-center justify-center cursor-pointer"
                v-for="(project, index) in $t(`pages['${projectKind}']`)"
                :key="index"
                @click="goToPage(project.link)"
            >
                <div class="project absolute text-center w-11/12">{{project.name}}</div>
                <figure>
                    <img :src="require(`../assets/images/${menuElement.folder}/thumbnails/${index+1}.jpg`)" class="max-w-full w-full h-auto" alt />
                </figure>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ProjectOverview",
    beforeRouteEnter(to, from, next) {
        next(vm => {
            if (!vm.menuElement) {
                next("/");
            } else {
                vm.$refs.overviewContainer.scrollTo(0, 0);
                document.title = vm.menuElement.name;
            }
        });
    },
    beforeRouteUpdate(to, from, next) {
        this.$refs.overviewContainer.scrollTo(0, 0);
        document.title = this.menuElement.name;
        next();
    },
    computed: {
        projectKind() {
            return this.$route.params.projectKind;
        },
        menuElement() {
            return this.$i18n.messages[this.$i18n.locale].menu.find(
                ({ link }) => link === this.$route.path
            );
        }
    },
    methods: {
        goToPage(projectId) {
            if (projectId !== undefined) {
                this.$router.push({
                    name: "projectDetail",
                    params: {
                        projectKind: this.projectKind,
                        projectId
                    }
                });
            }
        }
    }
};
</script>

<style scoped>
.overflow-y-auto {
    scroll-behavior: smooth;
}

.project {
    display: none;
}

.item:hover > figure {
    opacity: 0.25;
    transition: opacity 0.5s;
}

.item:hover > .project {
    display: block;
}
</style>
