<template>
    <div class="flex-auto overflow-y-auto flex">
        <div class="grid grid-cols-4 gap-4">
            <div class="col-span-4 md:col-span-2">
                <template v-if="$i18n.locale === 'en'">
                    <p class="indent mb-4">
                        iAMarq is an international architecture firm with its main office in Madrid, founded in
                        2012 by two architects, Álvaro García y Mercedes López. They decided to create
                        their own office together after more than 14 years working as a team in other firms.
                        iAMarq professional trajectory has positioned the firm not as a specialized in a
                        specific area of expertise but moreover, as a team able to approach any architectural
                        problem arisen, always keeping in mind the good quality of the results to be delivered
                        to the client.
                    </p>
                    <p class="indent mb-4">
                        Mercedes and Alvaro founded iAMarq almost a decade ago and their firm
                        accumulates large experience as Local Architect for Singular Buildings, Museums,
                        Corporate Buildings, Residential, Retail, and public Buildings. The assignment as
                        Team Directors at the Local Team (MiB Arquitectos) for David Chipperfield
                        Architects at the Segovia Centre of Art and Technologies and for Herzog&de Meuron
                        at CaixaForum Madrid can be pointed out.
                    </p>
                    <p class="indent mb-4">
                        Furthermore, within the professional works dedicated to Corporate Architecture, it
                        could be pointed out the assignment with TP Bennet for Headquarters of a Financial
                        Entity, at Jose Abascal in Madrid or the collaboration as Local Architects for David
                        Chipperfield Architects for Banco Santander Headquarters Rehabilitation at
                        Santander.
                    </p>
                    <p class="indent mb-4">
                        Experience provides perspective to foresee the possible problems that might arise
                        and to be able to control the risks and expedite the decisions. Always caring for a
                        fluid and close communication with all stakeholders involved in the project, so the
                        clients are fully satisfied with the results delivered.
                        Working as Local Architects has been key to iAMarq work and it helps to understand
                        how the team values the creating process as much as the later processes that
                        transform the first ideas into reality, after the concept stage.
                    </p>
                </template>
                <template v-else>
                    <p class="indent mb-4">
                        iAMarq es un estudio internacional de Arquitectura y Urbanismo con sede en
                        Madrid, fundado en 2012 por los arquitectos Álvaro García y Mercedes López, que
                        surge como continuación de una experiencia de más de 14 años de trabajo en
                        común de ambos socios.
                    </p>
                    <p class="indent mb-4">
                        La trayectoria profesional de iAMarq, se ha ido dibujando alejada de la
                        especialización tradicionalmente esperada por el cliente general, ya que acumula
                        experiencia en varios campos de la arquitectura. Alejados de la especialización en
                        un área concreta, pero especialistas en el trabajo bien hecho.
                    </p>
                    <p class="indent mb-4">
                        Así, iAMarq lleva ya casi una década caminando, y aglutina gran experiencia
                        como arquitectos locales para edificios singulares como museos, edificios
                        corporativos, vivienda, locales comerciales, o edificios públicos. Podemos destacar
                        entre otros, el trabajo como Equipo de Arquitectura Local de David Chipperfield
                        Architects para el proyecto del Banco Santander en su sede del Paseo de Pereda
                        en la capital Cántabra o el trabajo para el despacho de Herzog & de Meuron para el
                        proyecto de CaixaForum Madrid.
                    </p>
                    <p class="indent mb-4">
                        En el campo de la Rehabilitación iAMarq ha intervenido en edificios en
                        Santiago de Compostela, en el propio CaixaForum y en edificios residenciales y
                        comerciales en Madrid con elementos protegidos.
                    </p>
                    <p class="indent mb-4">
                        En iAMarq se considera tan importante el proceso de ideación arquitectónica
                        como los procesos posteriores que convierten en una realidad fiel las ideas de
                        origen. La experiencia desarrollando Proyectos De Ejecución y Dirección De Obra
                        sobre la base de proyectos de diseño de otros equipos de arquitectos de reconocido
                        prestigio internacional, es una de las claves que marcan y diferencian su trabajo.
                    </p>
                </template>
            </div>
            <div class="col-span-4 md:col-span-2 flex items-center">
                <div class="grid grid-cols-2 gap-4">
                    <figure class="col-span-1">
                        <img src="../assets/images/practice/estudio1.jpg" class="max-w-full w-full h-auto" alt />
                    </figure>
                    <figure class="col-span-1">
                        <img src="../assets/images/practice/estudio2.jpg" class="max-w-full w-full h-auto" alt />
                    </figure>
                    <figure class="col-span-1">
                        <img src="../assets/images/practice/estudio3.jpg" class="max-w-full w-full h-auto" alt />
                    </figure>
                    <figure class="col-span-1">
                        <img src="../assets/images/practice/estudio4.jpg" class="max-w-full w-full h-auto" alt />
                    </figure>
                    <figure class="col-span-2">
                        <img src="../assets/images/practice/estudio5.jpg" class="max-w-full w-full h-auto" alt />
                    </figure>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import titleMixin from "@/mixins/titleMixin";

export default {
    name: "About",
    mixins: [titleMixin]
};
</script>

<style scoped>
.indent {
    text-indent: 1.5rem;
}
</style>
