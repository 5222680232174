<template>
    <div id="app" class="w-screen h-screen flex items-center">
        <div class="container mx-auto h-full flex flex-col p-4">
            <div id="header" class="flex-initial flex mb-4">
                <div class="flex-1 flex items-center h-6">
                    <router-link to="/" v-if="$route.name !== 'home' || (showMenu && isMobile)">
                        <figure>
                            <img src="./assets/images/logo.svg" width="72px" alt="iAMarq" />
                        </figure>
                    </router-link>
                    <div v-if="showMenu || !isMobile" class="ml-auto absolute sm:static right-0 bottom-0 mb-2 mr-2 z-10">
                        <button @click="changeLocale('en')" class="focus:outline-none" :class="{'text-gray': $i18n.locale !== 'en'}">ENG</button> ||
                        <button @click="changeLocale('es')" class="focus:outline-none" :class="{'text-gray': $i18n.locale !== 'es'}">ESP</button>
                    </div>
                    <div class="sm:hidden ml-auto flex flex-col h-full justify-around" @click="showMenu=!showMenu">
                        <div class="bar w-5 bg-black"></div>
                        <div class="bar w-5 bg-black"></div>
                        <div class="bar w-5 bg-black"></div>
                    </div>
                </div>
            </div>
            <router-view></router-view>
            <nav-menu v-if="showMenu || !isMobile" @menu-click="showMenu=!showMenu"></nav-menu>
        </div>
    </div>
</template>

<script>
import NavMenu from "@/components/NavMenu.vue";

export default {
    name: "App",
    components: {
        NavMenu
    },
    data() {
        return {
            showMenu: false,
            isMobile: false
        };
    },
    beforeMount() {
        const hasChildElement = this.$i18n.messages.es.menu.find(({ link }) =>
            this.$route.path.startsWith(link)
        );

        if (hasChildElement) {
            this.$i18n.locale = "es";
        }

        const windowWidth = window.innerWidth || document.body.clientWidth;
        this.isMobile = windowWidth < 640;
    },
    methods: {
        changeLocale(locale) {
            if (this.$route.name !== "home") {
                this.$router.push("/");
            }
            this.$i18n.locale = locale;
            this.showMenu = false;
        }
    }
};
</script>

<style>
@font-face {
    font-family: "Swis721 BT";
    src: url("/fonts/Swiss721BT-Roman.woff2") format("woff2"),
        url("/fonts/Swiss721BT-Roman.woff") format("woff"),
        url("/fonts/Swiss721BT-Roman.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
    font-display: auto;
}

#app {
    color: #3f3f3f;
    font-family: "Swis721 BT";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@media (min-width: 1024px) {
    .container {
        max-height: 800px;
    }
}

#header {
    min-height: 24px;
}

.bar {
    height: 2px;
}
</style>
