import Vue from 'vue';
import VueRouter from 'vue-router';

import Home from '../views/Home.vue';
import Practice from '../views/Practice.vue';
import Contact from '../views/Contact.vue';
import ProjectOverview from '../views/ProjectOverview.vue';
import ProjectDetail from '../views/ProjectDetail.vue';

Vue.use(VueRouter);

export default new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            name: 'home',
            component: Home
        },
        {
            path: '/(practice|estudio)',
            name: 'practice',
            component: Practice
        },
        {
            path: '/contact(o|)',
            name: 'contact',
            component: Contact
        },
        {
            path: '/:projectKind',
            name: 'projectOverview',
            component: ProjectOverview
        },
        {
            path: '/:projectKind/:projectId',
            name: 'projectDetail',
            component: ProjectDetail
        }
    ]
});
