<template>
    <div class="flex flex-col h-full w-10/12 mx-auto">
        <div class="flex-1 flex items-center justify-center">
            <img src="../assets/images/logo.svg" alt="iAMarq" />
        </div>
    </div>
</template>

<script>
export default {
    name: "Home"
};
</script>

<style lang="css" scoped>
@media screen and (max-width: 767px) {
    img {
        width: 200px;
    }
}

@media screen and (min-width: 768px) {
    img {
        width: 350px;
    }
}
</style>
