export default {
    beforeRouteEnter(to, from, next) {
        next(() => {
            const name = to.path
                .split('/')
                .slice(-1)
                .pop();

            document.title = name.charAt(0).toUpperCase() + name.slice(1);
        });
    }
};
