<template>
    <div class="flex-auto flex flex-col justify-center" v-if="project">
        <div class="flex-1 flex justify-center items-center mb-6">
            <div class="grid grid-cols-12 gap-4 w-full">
                <div class="col-span-1">
                    <div class="h-full flex items-center">
                        <img src="../assets/images/arrow_left.png" width="16px" class="cursor-pointer" @click="dec()" />
                    </div>
                </div>
                <div class="col-span-10">
                    <div class="flex justify-center">
                        <figure>
                            <img :src="require(`../assets/images/${menuElement.folder}/${projectIdDefault}/${photoIndex}.jpg`)" class="h-full w-full" :alt="project.name" />
                        </figure>
                    </div>
                </div>
                <div class="col-span-1">
                    <div class="h-full flex items-center">
                        <img src="../assets/images/arrow_right.png" width="16px" class="cursor-pointer ml-auto" @click="inc()" />
                    </div>
                </div>
            </div>
        </div>
        <div class="flex-initial mb-4">
            <p>{{project.name}}</p>
            <p v-html="project.description" class="text-justify text-sm"></p>
        </div>
    </div>
</template>

<script>
export default {
    name: "ProjectDetail",
    beforeRouteEnter(to, from, next) {
        next(vm => {
            if (!vm.project) {
                next("/");
            } else {
                document.title = vm.project.name;
            }
        });
    },
    data() {
        return {
            photoIndex: 1
        };
    },
    beforeMount() {
        window.addEventListener("keydown", this.onKeyPress);
    },
    beforeDestroy() {
        window.removeEventListener("keydown", this.onKeyPress);
    },
    computed: {
        projectKind() {
            return this.$route.params.projectKind;
        },
        menuElement() {
            return this.$i18n.messages[this.$i18n.locale].menu.find(
                ({ link }) => link === `/${this.projectKind}`
            );
        },
        project() {
            if (!this.projectKind) return null;
            return this.$i18n.messages[this.$i18n.locale].pages[
                this.projectKind
            ].find(({ link }) => link === `${this.$route.params.projectId}`);
        },
        projectIdDefault() {
            return this.$i18n.messages.en.pages[this.menuElement.folder][
                this.project.index
            ].link;
        }
    },
    methods: {
        onKeyPress(e) {
            if (e.key === "ArrowRight") {
                this.inc();
            }
            if (e.key === "ArrowLeft") {
                this.dec();
            }
        },
        inc() {
            if (this.photoIndex === this.project.photosAmount) {
                this.photoIndex = 1;
            } else {
                this.photoIndex++;
            }
        },
        dec() {
            if (this.photoIndex === 1) {
                this.photoIndex = this.project.photosAmount;
            } else {
                this.photoIndex--;
            }
        }
    }
};
</script>
