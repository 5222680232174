<template>
    <div id="nav" class="flex-initial flex flex-col -mx-2 sm:-mx-4 inset-0 absolute sm:static bg-white p-4 sm:p-0">
        <ul class="flex flex-col sm:flex-row w-full items-end sm:justify-between">
            <li class="flex border-b sm:border-b-0 border-black sm:border-white" v-for="item in $t('menu')" :key="item.name">
                <router-link class="p-2 sm:p-4 hover:text-black" :class="$route.name === 'home' ? 'text-black' : 'text-gray'" :to="item.link" @click.native="menuClick()">{{item.name}}</router-link>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: "NavMenu",
    mounted() {
        window.addEventListener("resize", () => {
            // We execute the same script as before
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty("--vh", `${vh}px`);
        });
    },
    methods: {
        menuClick() {
            this.$emit("menu-click");
        }
    }
};
</script>

<style scoped>
li a.router-link-active {
    color: #3f3f3f;
}

@media (max-width: 640px) {
    #nav {
        top: 40px;
        height: 100vh;
        height: calc(var(--vh, 1vh) * 100);
    }
}
</style>
